@import "../../_globalColor";

// .main {
//   width: 90%;
//   padding: 20px 10px;
//   margin: 0px auto;
//   margin-top: 4rem;
// }
// .greet-main {
//   width: 90%;
//   padding: 20px 10px;
//   margin: 0px auto;
//   margin-top: 2rem;
// }

// .greeting-main {
//   display: flex;
// }

// .subTitle {
//   color: $subTitle !important;
// }

// .greeting-main {
//   display: flex;
// }

// .greeting-main > * {
//   flex: 1;
//   margin-bottom: 30px;
// }
// .button-greeting-div {
//   display: flex;
//   margin-top: 20px;
// }
// .download-link-button {
//   text-decoration: none;
// }

// .greeting-text {
//   font-size: 70px;
//   line-height: 1.1;
//   color: $textColor !important;
// }

// .greeting-text-p {
//   font-size: 30px;
//   line-height: 40px;
// }

.greeting-image-div > img {
  max-width: 100%;
  height: auto;
}

.wave-emoji {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

// /* Media Query */
// @media (max-width: 1380px) {
//   .greeting-text {
//     font-size: 50px;
//   }
//   .greeting-text-p {
//     font-size: 20px;
//   }
// }
// @media (max-width: 768px) {
//   .button-greeting-div {
//     justify-content: space-around;
//   }
//   .greeting-text {
//     font-size: 30px;
//     text-align: center;
//   }
//   .greeting-text-p {
//     font-size: 16px;
//     line-height: normal;
//     text-align: center;
//   }
//   .greeting-main {
//     display: block;
//   }
//   .greeting-image-div {
//     height: 256px;
//   }
// }

// @media (max-width: 320px) {
//   .main {
//     width: auto;
//   }
// }
.greet-main{
  width:90%;
  margin:0px auto;
  padding:20px 10px;
  margin-top:2rem;
  
}
.greeting-main{
  display:flex;
}
.subTile{
  color: $subTitle !important;
}
.greeting-main > *{
  flex:1;
  margin-bottom:30px;
}
.button-greeting-div{
  display:flex;
  margin-top:20px;
}
.download-link-button{
  text-decoration:none;
}
.greeting-text-p {
     font-size: 30px;
    line-height: 40px;
    color:black !important
  }